import React, { useState } from "react";
import "../../styles/MainFeatures/Footer.css";
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaPhone,
  FaRegCalendarCheck,
  FaMoneyBill
} from "react-icons/fa";
import RSVP from "./RSVP"; // Import the RSVP component
import Contact from "./Contact"; // Import the Contact component
import Calendar from "./Calendar"; // Import the Calendar component
import Location from "./Location"; // Import the Location component
import Gift from "./Gift"; // Import the Gift component
import Wish from "./Wish";

function Footer({ language }) {  // Accept language prop
  const [isRSVPOpen, setIsRSVPOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isGiftOpen, setIsGiftOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isWishOpen, setIsWishOpen] = useState(false);

  return (
    <footer className="footer">
      <button className="footer-button" onClick={() => setIsRSVPOpen(true)}>
        <FaRegCalendarCheck />
        <span>{language === "EN" ? "RSVP" : "RSVP"}</span>
      </button>
      <button className="footer-button" onClick={() => setIsCalendarOpen(true)}>
        <FaCalendarAlt />
        <span>{language === "EN" ? "Calendar" : "Kalendar"}</span>
      </button>
      {/* <button className="footer-button" onClick={() => setIsGiftOpen(true)}>
        <FaMoneyBill />
        <span>{language === "EN" ? "Money Gift" : "Salam Kaut"}</span>
      </button> */}
      <button className="footer-button" onClick={() => setIsLocationOpen(true)}>
        <FaMapMarkerAlt />
        <span>{language === "EN" ? "Location" : "Lokasi"}</span>
      </button>
      <button className="footer-button" onClick={() => setIsContactOpen(true)}>
        <FaPhone />
        <span>{language === "EN" ? "Contact" : "Hubungi"}</span>
      </button>

      {/* Modals */}
      <RSVP isOpen={isRSVPOpen} onRequestClose={() => setIsRSVPOpen(false)} language={language} />
      <Calendar
        isOpen={isCalendarOpen}
        onRequestClose={() => setIsCalendarOpen(false)}
      />
      <Gift isOpen={isGiftOpen} onRequestClose={() => setIsGiftOpen(false)} language={language} />
      <Location
        isOpen={isLocationOpen}
        onRequestClose={() => setIsLocationOpen(false)}
      />
      <Contact
        isOpen={isContactOpen}
        onRequestClose={() => setIsContactOpen(false)}
      />
      <Wish
        isOpen={isWishOpen}
        onRequestClose={() => setIsWishOpen(false)}
      />
    </footer>
  );
}

export default Footer;
